<template>
  <div class="my-account-page">
    <headers />
    <div class="account-content">
      <div class="left-menu">
        <div class="my-account">我的账户</div>
        <menus />
      </div>
      <right-icon />
      <div class="right-content">
        <router-view />
      </div>
    </div>
  </div>
</template>

<script>
import Headers from '@/components/Header/index.vue'
import Menus from './components/menu.vue'
import RightIcon from '@/components/right-icon/index.vue'
export default {
  name: 'MyAccount',
  components: {
    Headers,
    Menus,
    RightIcon
  },
  props: {},
  data() {
    return {}
  },
  computed: {},
  watch: {},
  created() { },
  methods: {}
}
</script>

<style scoped lang="scss">
.my-account-page {
  padding-top: 80px;
  padding-bottom: 20px;
  .account-content {
    display: flex;
    width: 1200px;
    height: calc(100vh - 140px);
    margin: 25px auto 0;
  }
  .left-menu {
    width: 160px;
    height: 100%;
    border: 1px solid #e8e9f0;
    box-shadow: 0px 2px 12px 0px #edecf6;
    margin-right: 30px;
    .my-account {
      height: 56px;
      padding: 0 20px;
      font-size: 16px;
      color: #373e4f;
      font-weight: 700;
      line-height: 59px;
      background-color: #f6f8fa;
      border-bottom: 1px solid #eceff5;
    }
  }
  .right-content {
    flex: 1;
    &>div{
      width: 1010px;
      height: 100%;
      min-height: 500px;
      padding: 20px;
      box-shadow: 0px 2px 12px 0px #edecf6;
      border-radius: 4px;
      &>div {
        height: 100%;
      }
    }
  }
}
</style>

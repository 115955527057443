<template>
  <el-menu
    :default-active="menuIndex"
    class="el-menu-vertical-demo"
    active-text-color="#0668f6"
    @select="handerMenuSelect"
    router
  >
    <template v-for="item in routeList">
      <el-menu-item :index="item.path" :key="item.path">
        <div class="menu-item">
          <span class="left-box" v-if="menuIndex == item.path"></span>
          <i class="icon icon-user-info" v-if="item.path == '/user-info'"></i>
          <i
            class="icon icon-recharge"
            v-else-if="item.path == '/recharge'"
          ></i>
          <i
            class="icon icon-recharge-record"
            v-else-if="item.path == '/recharge-record'"
          ></i>
          <i
            class="icon icon-use-record"
            v-else-if="item.path == '/use-record'"
          ></i>
          <i
            class="icon icon-query"
            v-else-if="item.path == '/query-record'"
          ></i>
          <i
            class="icon icon-invite-record"
            v-else-if="item.path == '/invite-record'"
          ></i>
          <span slot="title">{{ item.meta.title }}</span>
        </div>
      </el-menu-item>
    </template>
  </el-menu>
</template>

<script>
// import { coinOrderRecords } from '@/api/user.js'
import route from '@/router/modules/my.js'
export default {
  name: 'Menu',
  components: {},
  props: {},
  data() {
    return {
      menuIndex: '',
      routeList: []
    }
  },
  created() {
    this.routeList = route
    this.menuIndex = this.$route.path
    // this.coinOrderRecords()
  },
  methods: {
    // 获取充值记录信息
    // coinOrderRecords() {
    //   coinOrderRecords(this.params).then(res => {
    //     this.loading = false
    //     this.tableData = res.data.data
    //     this.total = res.data.totalRecord
    //   })
    // },
    handerMenuSelect(val) {
      this.menuIndex = val
    }
  },
  watch: {
    $route: {
      handler() {
        this.menuIndex = this.$route.path
      }
    }
  }
}
</script>

<style scoped lang="scss">
.el-menu-item:hover {
  background-color: #f2f5ff;
}
.is-active {
  background-color: #fff;
}
i {
  color: #303133;
}
.menu-item {
  display: flex;
  align-items: center;
  .left-box {
    width: 5px;
    height: 40px;
    background-color: #1747ff;
    margin-right: 15px;
    margin-left: -20px;
  }
  .icon {
    display: inline-block;
    margin-right: 8px;
    font-size: 20px;
    vertical-align: middle;
  }
}
</style>
